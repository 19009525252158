body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.widthlastMonth {
  width: 14% !important;
}

.fa {
  display: inline-flex !important;
}

.fa-s {
  font-size: 0.8em !important;
  margin-top: -6px;
  width: 12px;
  height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  vertical-align: middle;
  padding: 4px;
  border-radius: 51%;
  border: 1px solid grey;
  box-sizing: border-box;
}

.accordion-button {
  background: none !important;
  padding: 0.5rem 1rem !important;
}

.accordion-item {
  border: none !important;
}

.accordion-button:after,
.accordion-button:before {
  position: absolute;
  left: 0px;
  transform: rotate(-90deg);

}

.accordion-button:before {
  transform: rotate(90deg);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  font-size: 0.8em !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #222 !important;
  box-shadow: none !important;
}

.accordion-button::after {
  width: 11px !important;
  height: 10px !important;
  transform: rotate(-90deg);
  border-radius: 51%;
  border: 1px solid grey;
  background-size: 0.5rem !important;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
  background-size: 0.5rem !important;
  background-image: black !important
}

.accordion-body {
  padding: 0rem !important;
}

.accordion-header {
  padding-left: 0.3em;
}

button {
  font-weight: normal !important;
}

h6 {
  display: inline-flex;
}

.__react_component_tooltip {
  padding: 7px 5px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.setColor {
  color: darkgray;
  padding-left: 5px;
}

input[value="Invalid Date"] {
  color: red !important;
}
.prodStartDate[value="Invalid Date"] {
  color: red !important;
  font-size: 12px;
}
.pstart-date-picker-icon {
  position: relative;
  top: -2em;
  left: 5em;
}

.clspad {
  float: left;
  padding-right: 2rem;
}

​ .labelalign {
  display: flex !important;
  align-items: center !important;
  padding-left: 5rem !important;
}

.removeDisableColor {
  color: #222;
  padding-left: 10px;
}



.form-check-input:focus {
  box-shadow: none !important;
  border-color: none !important
}

.form-check-input {
  background-color: #fef2e0 !important
}

.form-check-input:checked {
  border-color: #000 !important;
}

.headerAlign {
  margin-top: 0px;
  position: relative;
}


.dispalyIn {
  display: inherit;
}

.checkpad {
  padding-left: 35px !important;
  padding: 0px;
}

.display {
  display: inline-flex;
}

.padLeftFormat {
  padding-left: 2rem !important;

}

.form-select {
  min-width: 130px !important;
}

/* .form-check-inline {
  width: 55% !important;
} */

.marLft {
  margin-left: 1rem !important;
}

.padRight {
  padding-right: 10px;
}

.form-select {
  width: 50% !important;
}

.clsToggle {
  padding-left: 13px;
  height: 15px !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.iconCls {
  margin-left: 10px;
}

.date-picker-icon {
  position: relative;
  top: -2em;
  left: 13em;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.se-date-picker-icon {
  margin-left: -25px;
  position: relative;
  margin-top: 12px;
}

.react-datepicker {
  font-size: 1rem !important;
}

.react-datepicker__navigation-icon--previous {
  right: 1px !important;
  top: 4px !important;
}

.react-datepicker__navigation-icon--next {
  left: 1px !important;
  top: 4px !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
  background-color: #5cb85c !important;
}

.react-datepicker__navigation {
  border-radius: 50% !important;
}

.fa-calendar-disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/* 
.tooltip-arrow {
  top: 0px !important;
} */

.tooltip-inner {
  max-width: 100% !important;
  margin-top: 5px !important;
}

.form-check-inline {
  display: inline-flex !important;
  padding-bottom: 5px;
}

.alignInput {
  display: -webkit-inline-box;
  margin-left: 1.3rem;
}

.seDate {
  display: -webkit-inline-box;
  /* margin-left: 1.3rem; */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.col-2 {
  width: 13% !important;
}

.circle {
  border: 1px solid gray;
  font-size: 12px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  line-height: 23px;
  color: #fff;
  text-align: center;
  background: grey;
  margin-right: 3px;
  margin-top: 3px;
  cursor: pointer;
}

.active {
  background-color: #17abab;
  cursor: pointer;

}

.tab-content>.active {
  background-color: #fff !important;
}

.not-active {
  background-color: #696969;

}

.card-header {
  border-bottom: none !important
}

.padText {
  padding-top: 0.4em;
  padding-right: 2.6rem;
  display: inline-flex;
}

.padTextDesc {
  padding-right: 0.7em;
}

.css-g1d714-ValueContainer {
  padding: 2px 5px !important;
}

.css-xb97g8 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1dhwdve {
  font-size: 13px !important;
}

button#fill-tab-example-tab-HeaderSearch,
button#fill-tab-example-tab-uwlList {
  background: white !important;
  border: 1px solid gray !important;
  color: #17abab !important;
  border-radius: 7px;
}

button#fill-tab-example-tab-HeaderSearch.active,
button#fill-tab-example-tab-uwlList.active {
  background: #e6f4fd !important;

}

#fill-tab-example-tab-uwlList {
  width: 118% !important;
  height: 38px;
  margin-left: 22px;
  font-weight: 500 !important;
  line-height: 1.2;
  font-size: 14px;
}

#fill-tab-example-tab-HeaderSearch {
  height: 38px;
  width: 118%;
  font-weight: 500 !important;
  line-height: 1.2;
  font-size: 14px;
}

.nav-tabs {
  border-bottom: none !important;
}

.tablebox {

  width: 100%;
  background-color: #F7F7F7;
  margin: 0 auto 10px;
  border-radius: 2px;
  line-height: 30px;
  cursor: auto;
  padding-bottom: 5px;
}

.tablebox label {
  float: left;
  width: 100%;
  margin-left: 13px;
  margin-top: 10px;
  margin-bottom: 2px;
  font-weight: bold;
  display: inline-flex !important;
  ;
}

@-moz-document url-prefix() {
  textarea {
    width: 18em;
  }
}

.tablebox textarea {
  display: block;
  margin-left: 0.8em !important;
  border-color: lightgray !important;
}

.uploadFile {
  cursor: pointer;
  color: #17abab;
  position: relative;
  bottom: 2.05rem;
  left: 6.05rem;
  font-weight: normal !important;
}

#upload {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.css-qc6sy-singleValue{
  overflow: visible !important;
  margin-left: 0px !important;
}
.css-tlfecz-indicatorContainer{
  padding: 0px !important;
}
.css-1wy0on6 {
    margin-left: -15px !important;
}