.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dropdown-content {
  display: block;
  position: absolute;
  right: 0px;
  background-color: #696969;
  color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 999999;
}

.bCls {
  border-bottom: 1px solid #FFF;
  float: none;
  color: #FFF;
  padding: 5px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.mtop {
  margin-top: -9px;
}

.dropdown {
  float: left;
  margin-right: 2em !important;
  /* overflow: hidden; */
}

.App header {
  background-color: #222;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0.5rem 1rem;
}

.cursorPointCls {
  cursor: pointer;
  float: none;
  color: #FFF;
  padding: 5px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.okta-text {
  text-align: center;
  color: #0000FF;
  margin: 1em;
}

.input-dev {
  margin: 0 auto;

}

.txtCenter {
  text-align: center
}

.mar-align {
  margin-top: 1rem;
}

/* apply CSS to the select tag of 
       .dropdown-container div*/

.dropdown-container select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}

/* for IE10 */
.dropdown-container select::-ms-expand {
  display: none;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.list-group-item {
  border: 1px solid #FFF;
}

p {
  font-size: 13px;
}

.card {
  border: 1px solid #FFF;
  background: #e6f4fd !important;
  min-height: 100vh;
}

.card-header {
  background-color: rgba(0, 0, 0, 0) !important;
}

.card-sec {
  padding-left: 0px !important;
}

button.btn-danger {
  background: #dc3545 !important;
  border-color: #dc3545 !important;
}

button {
  background: #17abab !important;
  border: 0 !important;
}

.MuiButton-root {
  padding: 6px 16px !important;
  border-radius: 2px !important;
}

.container-block {
  margin-top: 5rem;
}

.hide-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
}

.pad-align {
  padding: 0.5rem;
}

#cover-spin {
  position: fixed;
  margin-left: 406px;
  left: 0%;
  right: 0%;
  top: 15%;
  bottom: 1%;
  background-color: rgba(51, 51, 51, 0.3);
  z-index: 99999;
}

.alert {
  margin-top: 20px;
  width: 30%;
  text-align: center;
  margin: 0 auto;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 80px;
  height: 80px;
  border-style: solid;
  border-color: #DCDCDC;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.la-ball-clip-rotate,
.la-ball-clip-rotate>div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-clip-rotate {
  display: block;
  font-size: 0;
  color: #A9A9A9;
}

.la-ball-clip-rotate.la-dark {
  color: #333;
}

.la-ball-clip-rotate>div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-clip-rotate {
  width: 32px;
  height: 32px;
}

.la-ball-clip-rotate>div {
  width: 80px;
  height: 80px;
  background: transparent;
  border-width: 2px;
  border-bottom-color: transparent;
  border-radius: 100%;
  -webkit-animation: ball-clip-rotate .75s linear infinite;
  -moz-animation: ball-clip-rotate .75s linear infinite;
  -o-animation: ball-clip-rotate .75s linear infinite;
  animation: ball-clip-rotate .75s linear infinite;
}

.la-ball-clip-rotate.la-sm {
  width: 16px;
  height: 16px;
}

.la-ball-clip-rotate.la-sm>div {
  width: 16px;
  height: 16px;
  border-width: 1px;
}

.la-ball-clip-rotate.la-2x {
  width: 64px;
  height: 64px;
}

.la-ball-clip-rotate.la-2x>div {
  width: 64px;
  height: 64px;
  border-width: 4px;
}

.la-ball-clip-rotate.la-3x {
  width: 96px;
  height: 96px;
}

.la-ball-clip-rotate.la-3x>div {
  width: 96px;
  height: 96px;
  border-width: 6px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes ball-clip-rotate {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes ball-clip-rotate {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mar-top {
  margin-top: 10px;
}

.countCards {
  width: 100% !important;
}

.clrSearch {
  font-size: 14px !important;
  text-decoration: none;
  color: #17abab;
}


/* @media screen and (max-width: 1050px) and (min-width: 600px) {
  .searchCol { width: 4%; }
.filterCol { max-width: 25% !important; }
} */
.btn-info {
  background-color: #17abab !important;
}

a:active {
  color: none !important;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.searchCol {
  max-width: 54px;
  width: 100%;
}

.filterCol {
  max-width: 335px;
  width: 100%;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  border-right: 2px solid #17abab !important;
  padding-left: 10px;
  padding-right: 10px;
}

/* SIDEBAR BUTTON CSS */
.list-group-item {
  padding: 0px !important;
  margin-top: -4px;
}

.btnCls {

  /* background: #85c8ea;
    border-radius: 3px 0 0 3px;
    color: #fff !important;
    display: inline-block !important;
    height: 26px;
    float: left;
    line-height: 26px; */
  /* padding: 0 30px 0 10px; */
  padding: 18px 15px -1px 10px;
  position: relative;
  /* margin: 0 3px 3px 0; */
  width: 50px;
  height: 40px;
  text-decoration: none;
  /* -webkit-transition: color 0.2s; */

}

.btnCls::before {
  /* background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgb(0 0 0 / 25%); */
  content: '';
  height: 6px;
  right: 13px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.btnCls::after {
  /* background: #fff; */
  border-bottom: 13px solid transparent;
  border-left: 10px solid #17abab;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: -6px;
  top: 8px;
}

.higlightState {
  font-weight: bold
}

.Headers {
  color: darkGray;
}


ul.menu {
  list-style: none;
}

ul.menu li {
  display: inline;
  padding: 12px;
}

ul.menu a {
  color: #ffffff;
}

.page {
  text-align: center;
}

button {
  background-color: #6200ee;
  color: #ffffff;
  border: none;
  padding: 12px 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #3700b3;
}

.warning {
  width: 800px;
  margin: 24px auto;
  border: 5px solid #ff0000;
  border-radius: 15px;
}

.warning h1 {
  margin-top: 0;
  color: white;
  font-size: 48px;
  line-height: 96px;
  background-color: #ff0000;
  text-transform: uppercase;
}

.warning h2 {
  font-size: 36px;
  text-transform: uppercase;
}

.marTop {
  margin-top: 10px;
}

.higlightState {
  font-weight: bold
}

.Headers {
  color: darkGray;
}

.css-319lph-ValueContainer {
  text-align: left;

}

div.css-xb97g8,
.css-12jo7m5 {
  background-color: #fff !important;

}

#react-select-3-listbox {
  text-align: left;
  background-color: #fff !important;
  display: block;

}

.highlightOption {
  background: #f5faff !important;
  color: #333 !important;

}

.multiSelectContainer li:hover {
  background: #f5faff !important;
  color: #333 !important;

}

div#react-select-5-listbox {
  text-align: left;
  background-color: #fff !important;
  ;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin {
  position: fixed;
  margin-left: 390px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 1%;
  background-color: rgba(51, 51, 51, 0.3);
  z-index: 99999;
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 80px;
  height: 80px;
  border-style: solid;
  border-color: #DCDCDC;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.css-1okebmr-indicatorSeparator {
  background-color: #fff !important;
}

#react-select-2-listbox {
  text-align: left !important;
}

.css-1d8n9bt {
  flex: none !important;
}

.cont {
  font-weight: 'normal'
}

.cont2 {
  font-weight: 'bold'
}

/* label {
  display: block !important;
} */

.checkBox {
  padding: 0.16rem 1.75rem !important;
}

.form-check-input:checked {
  border-color: none !important
}

.clrSearch:hover {
  color: #17abab !important;
}

/* @media (min-width: 576px) {
  .modal-dialog {
    max-width: 663px !important;
    margin: 1.75rem auto;
  }
} */


#export-cover-spin {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(51, 51, 51, 0.3);

}

#export-cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 80px;
  height: 80px;
  border-style: solid;
  border-color: #DCDCDC;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}


.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23000000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !important;
}

.form-check-input[type=checkbox] {
  border-radius: 0px !important;
}

.uwiErr {
  width: 285px;
  color: #f93154;
  margin-top: -0.75rem;
  font-size: 12px;
  text-align: left;
  margin-left: 14px;
  line-height: 18px;
}

.uwiGrid {
  position: absolute;
  top: 27%;
  margin-left: -25px;
}

.uwicardInside {
  margin: auto;
  width: 300px;
  height: 100px;
  line-height: 35px;
  margin-top: 10rem;
}

.lastMonth input.form-control {
  padding: 0px !important
}